import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Tooltip from "rc-tooltip"
import "rc-tooltip/assets/bootstrap.css"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SkillsPage = ({ data }) => {
  const { Title, Slug, Description } = data.strapiPages
  const skills = data.allStrapiSkills.nodes
  return (
    <Layout>
      <SEO title={Title} />
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-top"
        id={Slug}
      >
        <div className="w-100">
          <h2 className="mb-5 h1">{Title}</h2>

          <div className="subheading mb-3">
            Programming Languages &amp; Tools
          </div>
          <ul className="list-inline dev-icons">
            {skills.map(skill => (
              <li
                key={skill.id}
                aria-label={skill.Name}
                className="list-inline-item"
              >
                <Tooltip placement="top" overlay={skill.Name}>
                  <i className={`fab ${skill.Fontawesome}`}></i>
                </Tooltip>
              </li>
            ))}
          </ul>

          <ReactMarkdown source={Description} />
        </div>
      </section>
    </Layout>
  )
}

export default SkillsPage

export const pageQuery = graphql`
  query SkillsQuery {
    strapiPages(Slug: { eq: "skills" }) {
      Title
      Slug
      Description
    }
    allStrapiSkills(sort: { fields: created_at, order: DESC }) {
      nodes {
        id
        Fontawesome
        Name
      }
    }
  }
`
